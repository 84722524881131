import themeVars, { hexToRGB } from '../theme-vars'
import menuCorner from '../../assets/svg/menu_corner_wholesale.svg'

const headerStyles = {
  '*.offCanvasWrapper': {
    pointerEvents: 'auto',
    background: `linear-gradient(to bottom, ${themeVars.colors.blue}, ${hexToRGB(themeVars.colors.slate, 0.8)})`,
    '.mobileMenuAccordions': {
      '> a': { // Non-accordion item
        '&.rez': {
          marginTop: '30px'
        },
        '&.rez, &.biz': {
          fontWeight: 'normal'
        },
        '&.biz': {
          marginTop: '0px',
        }
      }
    }
  },
  // GLOBAL MAP
  '.wsMap': {
    position: 'absolute',
    width: '100%',
    maxWidth: '1440px',
    marginTop: '0px',
    top: '150px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 3,
    svg: {
      width: '100%',
      maxWidth: '85vw',
      height: 'auto',
      marginLeft: 'auto',
      '.globalGroup': {
        mask: 'url(#southMask)'
      }
    },
    '@media only screen and (min-width: 1024px)': {
      height: 'auto',
      svg: {
        maxWidth: '83%'
      }
    }
  },
  '#mtaline,#mtaline2,#mtaline3,#mtaline4': {
    strokeWidth: '4px',
    stroke: '#63e0c9',
    strokeDasharray: '230',
    strokeDashoffset: '1380',
    animation: 'dash 7s linear forwards infinite'
  },
  '#mtaline3': {
    animationDirection: 'reverse',
  },
  '#mtaline2,#mtaline4': {
    strokeDasharray: '200'
  },
  '@keyframes dash': {
    'to': {
      strokeDashoffset: 0
    }
  },
  // MAP LEGEND
  '.legendContainer': {
    pointerEvents: 'none',
    width: '100%',
    height: 'calc(95vw + 150px)',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 5,
    '.legend': {
      width: '100%',
      maxWidth: '330px',
      position: 'absolute',
      left: '15px',
      bottom: 0,
      transformOrigin: '0 0',
      transform: 'scale(0.75)',
      '> div': {
        color: themeVars.colors.white,
        minHeight: '100px',
        borderRadius: '5px',
        padding: '10px 15px',
        background: `linear-gradient(150deg, ${themeVars.colors.mint} -30%, ${themeVars.colors.blue})`,
        boxShadow: '5px 5px 0 rgba(119, 141, 153, 0.4)',
        h6: {
          fontWeight: 'bold!important',
          marginBottom: '5px!important',
          fontSize: '16px!important;'
        },
        table: {
          width: '100%',
          padding: 0,
          margin: 0,
          borderCollapse: 'collapse',
          tr: {
            td: {
              fontSize: '14px',
              width: '40%',
              fontWeight: 'normal',
              padding: '4px',
              '&.land, &.sea': {
                div: {
                  height: '3px',
                  backgroundColor: themeVars.colors.mint,
                }
              },
              '&.sea': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingTop: '8px',
                div: {
                  height: '3px',
                  maxWidth: '23px',
                  width: '100%',
                  backgroundColor: hexToRGB(themeVars.colors.blue, 0.8),
                }
              },
              '&.pop': {
                img: {
                  maxWidth: '50px',
                }
              },
              '&:first-of-type': {
                paddingLeft: 0,
              }
            }
          }
        }
      }
    },
    '@media only screen and (min-width: 1024px)': {
      height: 'calc(70vw + 150px)',
      '.legend': {
        left: '30px',
        transform: 'scale(1)',
      }
    },
    '@media only screen and (min-width: 1440px)': {
      height: '1150px',
    },
  },
  '.slideButtons': {
    display: 'flex',
    gridGap: '10px',
    justifyContent: 'space-around',
},
  '@media (min-width: 1024px)': {
      '.slideButtons': {
        display: 'flex',
        gridGap: '10px',
        justifyContent: 'flex-start',
    },
  },
  '*.headerContainer': { // Added * to make selector different from residential, so it adds it instead of replacing it, which loses all our base styles
    height: 'calc(65vw + 150px)',
    '&::before': {
      background: `linear-gradient(180deg, transparent 99.8%, white 99.9%), linear-gradient(135deg, ${hexToRGB(themeVars.colors.newblue, 0.65)} 20%, ${hexToRGB(themeVars.colors.darkBlue, 0.65)})`,
    },
    '@media only screen and (min-width: 1024px)': {
      height: 'calc(55vw + 150px)',
    },
    '@media only screen and (min-width: 1440px)': {
      height: '970px',
    },
    'header.header': {
      '.sliderBG': {
        left: 'unset',
        right: '0',
        overflow: 'visible',
        svg: {
          left: 'unset',
          right: 0,
          width: '150vw',
        },
        /* 'svg:last-of-type': {
          display: 'none'
        }, */
        '@media (min-width: 1024px)': {
          right: '420px',
          svg: {
            width: '120vw',
          }
        }
      },
      // SLIDER
      '.sliderContainer': {
        marginTop: '80px',
        '@media (min-width: 640px)': {
          marginTop: '100px',
        },
        '@media (min-width: 1024px)': {
          marginTop: '-20px'
        },
        '@media (min-width: 1200px)': {
          marginTop: '20px'
        },
        '.activator': {
          marginTop: '20px',
          '@media (max-width: 450px)': {
            width: 'max-content',
            fontSize: '14px',
            marginTop: '100px',
            backgroundColor: hexToRGB(themeVars.colors.blue, 0.8),
            marginLeft: '-15px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            lineHeight: 1,
            padding: '10px 10px 10px 15px',
            // borderBottom: `3px solid #778c99`,
            // borderRight: `2px solid #778c99`
          }
        },
        '.slick-slider': {
          '.slick-dots': {
            'li': {
              'button::before': {
                border: `3px solid ${themeVars.colors.blue}`,
              },
              '&.slick-active': {
                'button::before': {
                  backgroundColor: themeVars.colors.blue,
                }
              }
            }
          }
        },
      },
      '.topBarMobile': { // MOBILE HEADER
        pointerEvents: 'auto!important',
        '&::before': {
          backgroundImage: `linear-gradient(135deg, ${hexToRGB(themeVars.colors.darkBlue, 0.96)}, ${hexToRGB(themeVars.colors.blue, 0.96)})`,
        },
        '> div:first-of-type': {
          display: 'none'
        },
        '> div:nth-of-type(2)': {
          justifyContent: 'flex-start',
          svg: {
            maxWidth: '185px',
            minWidth: '160px',
            width: '100%',
            transition: 'max-width 0.2s ease-in-out',
            // marginTop: '10px',
            'path, polygon': {
              fill: themeVars.colors.white,
            }
          }
        },
        '> div:last-of-type': {
          '.hamburger-react': {
            marginTop: '-10px',
          }
        },
        '&.transp': {
          '> div:nth-of-type(2)': {
            svg: {
              maxWidth: '235px',
            }
          }
        }
      },
      '.topBar': { // DESKTOP HEADER
        padding: '30px',
        'nav.navSuper': {
          'ul.sisterSites': {
            pointerEvents: 'auto',
            li: {
              a: {
                color: themeVars.colors.white,
                fontWeight: 200,
                opacity: 0.8,
                transition: 'opacity 0.2s ease-in-out',
                '&:hover': {
                  opacity: 1
                },
                '&.active': {
                  opacity: 1
                },
              }
            }
          },
          'ul.userControls': {
            alignItems: 'center',
            pointerEvents: 'auto',
            '> li': {
              '> a': {
                color: themeVars.colors.white,
                opacity: 0.5,
                '&:hover': {
                  opacity: 1
                }
              },
              '&:not(:last-child)': {
                marginRight: '14px'
              }
            }
          }
        },
        'nav.navPrimary': {
          color: themeVars.colors.slate,
          gridTemplateColumns: '405px 1fr',
          '.logo': {
            maxWidth: '305px',
            marginTop: '-13px',
            '#MTALogoBiz, #MTALogoWholesale': {
              'path, polygon': {
                fill: themeVars.colors.white,
              },
              '.logoOutline': {
                stroke: themeVars.colors.white,
                fill: 'none',
              },
            },
            '@media (min-width: 1200px)': {
              maxWidth: '405px'
            }
          },
          'ul.mainMenu': {
            pointerEvents: 'auto',
            paddingTop: '12px',
            '@media (min-width: 1200px)': {
              paddingTop: '25px',
            },
            li: {
              '&:not(:last-child)': {
                marginRight: '14px',
                '@media (min-width: 1200px)': {
                  marginRight: '20px',
                }
              },
              'a, a.subMenuParent': {
                color: themeVars.colors.white,
                opacity: 0.8,
                fontSize: '17px',
                '&:hover': {
                  color: themeVars.colors.white,
                  opacity: 1
                }
              },
              'div.chakra-menu__menu-list': { // SUBMENU DROPDOWN
                backgroundColor: hexToRGB(themeVars.colors.blue, 0.55),
                boxShadow: `8px 8px 0 ${hexToRGB(themeVars.colors.blue, 0.35)}`,
                button: {
                  a: {
                    color: themeVars.colors.white,
                  },
                  '&:hover, &:focus': {
                    backgroundColor: themeVars.colors.blue,
                    a: {
                      color: themeVars.colors.white,
                    }
                  }
                },
                '&::before, &::after': {
                  backgroundImage: `url(${menuCorner})`,
                }
              }
            }
          }
        }
      }
    }
  }
}

export default headerStyles