import themeVars from '../theme-vars'
import footerOverlay from '../../assets/svg/footer_overlay_esports.svg'

const footerStyles = {
  '* footer.footer': { // Added * to make selector key different from residential, so it adds it instead of replacing it, which loses all our base styles
    background: `linear-gradient(135deg, ${themeVars.colors.purple}, ${themeVars.colors.orange})`,
    '.footerOverlay': {
      backgroundImage: `url(${footerOverlay})`
    }
  }
}

export default footerStyles