import themeVars, { hexToRGB, remCalc } from '../theme-vars'

const headerStyles = {
  '*.offCanvasWrapper': {
    '.mobileMenuAccordions': {
      '> a': { // Non-accordion item
        '&.rez': {
          marginTop: '30px'
        },
        '&.rez, &.wholes': {
          fontWeight: 'normal'
        }
      }
    }
  },
  '.slideButtons': {
    display: 'flex',
    gridGap: '10px',
    justifyContent: 'space-around',
},
  '@media (min-width: 1024px)': {
      '.slideButtons': {
        display: 'flex',
        gridGap: '10px',
        justifyContent: 'flex-start',
    },
  },
  '*.headerContainer': { // Added * to make selector different from residential, so it adds it instead of replacing it, which loses all our base styles
    '&::before': {
      background: `linear-gradient(180deg, transparent 99.8%, white 99.9%), linear-gradient(135deg, ${hexToRGB(themeVars.colors.newblue, 0.95)} 20%, ${hexToRGB(themeVars.colors.darkBlue, 0.65)})`,
    },
    'header.header': {
      position: 'relative',
      '.sliderContainer': {
        '.slick-slider': {
          '.slick-dots': {
            'li': {
              'button::before': {
                border: `3px solid ${themeVars.colors.blue}`,
              },
              '&.slick-active': {
                'button::before': {
                  backgroundColor: themeVars.colors.blue,
                }
              }
            }
          }
        },
      },
      '*.topBarMobile': { // MOBILE HEADER
        '&::before': {
          backgroundImage: `linear-gradient(135deg, ${hexToRGB(themeVars.colors.darkBlue, 0.96)}, ${hexToRGB(themeVars.colors.blue, 0.96)})`,
        },
        '> div:nth-of-type(2)': {
          'svg.logoWhite': {
            maxWidth: '190px',
            width: '100%',
            'path, polygon': {
              fill: themeVars.colors.white,
            }
          }
        },
        '&.transp': {
          '> div:nth-of-type(2)': {
            'svg.logoWhite': {
              maxWidth: '235px'
            }
          },
        }
      },
      '.topBar': { // DESKTOP HEADER
        'nav.navSuper': {
          'ul.sisterSites': {
            li: {
              '&:not(:last-child)': {
                marginRight: remCalc(20),
              },
              /* '@media only screen and (min-width: 1200px)': {
                '&:not(:last-child)': {
                  marginRight: remCalc(16),
                },
              } */
            }
          },
        },
        'nav.navPrimary': {
          gridTemplateColumns: '405px 1fr',
          '.logo': {
            maxWidth: '330px',
            svg: {
              'path, polygon': {
                '&.logoFill': {
                  fill: themeVars.colors.white,
                },
                '&.logoOutline': {
                  stroke: themeVars.colors.white
                },
                '&.logoFill.orbitBgrad': {
                  fill: 'url(#orbitBgrad)'
                }
              }
            },
          },
          '@media only screen and (min-width:1200px)': {
            '.logo': {
              maxWidth: '405px',
            },
          },
          'ul.mainMenu': {
            li: {
              'div.chakra-menu__menu-list': { // SUBMENU DROPDOWN
                button: {
                  '&:hover, &:focus': {
                    backgroundColor: themeVars.colors.blue,
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

export default headerStyles