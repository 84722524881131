import themeVars, { remCalc, tracking, hexToRGB } from '../theme-vars'
import topbarMobileBG from '../../assets/svg/topbar_mobile_bg.svg'
import menuCorner from '../../assets/svg/menu_corner.svg'

const headerStyles = {
  '.offCanvasWrapper': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: '20%',
    paddingTop: '135px',
    background: `linear-gradient(to bottom, ${themeVars.colors.blue}, ${hexToRGB(themeVars.colors.mint, 0.8)})`,
    zIndex: 1398,
    transition: 'top 0.3s ease-in-out',
    textAlign: 'center',
    '@media (min-width: 1024px)': {
      top: 0
    },
    '.mobileMenuAccordions': {
      border: 0,
      overflowY: 'auto',
      maxHeight: '100%',
      '> a': { // Non-accordion item
        color: themeVars.colors.white,
        padding: '10px 5px',
        fontSize: remCalc(24),
        '&.biz': {
          marginTop: '30px'
        },
        '&.biz, &.wholes': {
          fontWeight: 'normal'
        }
      },
      '.accordion__item': { // Accordion item
        border: 0,
        '.accordion__heading': {
          '.accordion__button': {
            backgroundColor: 'transparent',
            padding: '10px 5px',
            textAlign: 'center',
            color: themeVars.colors.white,
            outline: 0,
            fontSize: remCalc(24),
            position: 'relative',
            display: 'inline-block',
            width: 'auto',
            '&::before': {
              position: 'absolute',
              right: '-30px',
              top: '50%',
              transformOrigin: '50%',
              transform: 'rotate(-45deg) translateY(-50%)'
            },
            '&[aria-expanded="true"]': {
              '&::before': {
                right: '-30px',
                top: '45%',
                transform: 'rotate(45deg) translateY(-50%)'
              }
            }
          }
        },
        '.accordion__panel': {
          padding: '8px 10px',
          ul: {
            listStyleType: 'none',
            li: {
              lineHeight: 1,
              a: {
                color: themeVars.colors.white,
                fontWeight: 200,
                fontSize: '22px',
              },
              ':not(:last-of-type)': {
                marginBottom: '18px'
              }
            }
          }
        }
      }
    },
  },
  '.headerContainer': { // Outtermost header element
    maxWidth: themeVars.bp.medium,
    margin: '0 auto',
    position: 'relative',
    marginTop: '120px',
    'header.header': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '50px',
      fontSize: '18px',
      '.sliderBG': { // Our swoopy background and svg overlay
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
        overflow: 'hidden',
        // height: '847px',
        width: '100%',
        // transition: 'width 0.3s ease-in-out',
        svg: {
          position: 'absolute',
          width: '160%',
          top: 0,
          left: 0,
        }
      },
      '.topBarMobile': { // MOBILE HEADER
        width: '100%',
        position: 'fixed',
        padding: '0 15px 40px 15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1399,
        minHeight: '135px',
        left: 0,
        top: 0,
        transition: 'opacity 0.2s ease-in-out, transform 0.3s ease-in-out',
        margin: '-1px',
        '&::before': {
          content: '""',
          // background: linear-gradient(45deg, rgba(99, 224, 201, 0.9), rgb(35, 81, 138)),
          backgroundImage: `url(${topbarMobileBG})`,
          backgroundSize: '100% 100%',
          opacity: 1,
          transition: 'opacity 0.2s ease-in-out, transform 0.3s ease-in-out',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: '-1',
          transform: 'translateY(0%)',
        },
        '&.transp': {
          '&::before': {
            opacity: 0,
            transform: 'translateY(-100%)',
          },
        },
        '> div': {
          flex: '1 1 33.333%',
        },
        '> div:nth-of-type(2)': {
          display: 'flex',
          justifyContent: 'center',
          'svg.logoWhite': {
            maxWidth: '115px',
            width: '100%',
            'path, polygon': {
              '&.logoFill': {
                fill: themeVars.colors.white,
              },
              '&.logoOutline': {
                stroke: themeVars.colors.white,
              }
            }
          }
        },
        '> div:last-child': {
          display: 'flex',
          justifyContent: 'flex-end',
          '.hamburger-react': {
            '> div': {
              height: '4px!important',
              background: themeVars.colors.blue,
            }
          }
        }
      },
      '.topBar': { // DESKTOP HEADER
        padding: '26px 30px',
        display: 'none',
        position: 'relative',
        'nav.navSuper': {
          marginBottom: '30px',
        },
        'nav.navPrimary': {
          fontSize: remCalc(20),
          color: themeVars.colors.white,
          fontWeight: 200,
          gridTemplateColumns: '165px 1fr',
          '.logo': {
            maxWidth: '165px',
            svg: {
              'path, polygon': {
                '&.logoFill': {
                  fill: themeVars.colors.blue,
                },
                '&.logoOutline': {
                  stroke: themeVars.colors.blue
                }
              }
            },
          },
          'ul.mainMenu': {
            listStyleType: 'none',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            li: {
              display: 'flex',
              '&:not(:last-child)': {
                marginRight: remCalc(20),
              },
              'a, a.subMenuParent': {
                fontFamily: themeVars.bodyFontFamily,
                fontWeight: 200,
                letterSpacing: tracking(30),
                color: themeVars.colors.white,
                cursor: 'pointer'
              },
              'a.subMenuParent': { // SUBMENU PARENT BUTTON
                background: 'none',
                padding: 0,
                '> span': {
                  display: 'flex'
                },
                svg: {
                  marginLeft: '5px',
                  maxWidth: '16px',
                  width: '100%',
                  maxHeight: '20px',
                },
                '&[data-active]': {
                  svg: {
                    '.iconFill, .TriangleDownInline__iconFill': {
                      opacity: 1,
                    }
                  }
                }
              },
              'a.searchLink': {
                svg: {
                  width: '100%',
                  maxWidth: '17px'
                }
              },
              '> div': {
                zIndex: 100
              },
              'div.chakra-menu__menu-list': { // SUBMENU DROPDOWN
                backgroundColor: hexToRGB('#e0e0e0', 0.25),
                border: 0,
                borderRadius: 0,
                padding: '15px 0',
                position: 'relative',
                boxShadow: `8px 8px 0 ${hexToRGB('#e0e0e0', 0.35)}`,
                '.chakra-menu__menuitem a': {
                  height: 'auto',
                  padding: '2px 0',
                  width: '100%'
                },
                '&::before, &::after': {
                  content: '""',
                  position: 'absolute',
                  backgroundImage: `url(${menuCorner})`,
                  width: '8px',
                  height: '8px',
                },
                '&::before': {
                  top: 0,
                  left: '100%',
                },
                '&::after': {
                  top: '100%',
                  left: 0,
                  transform: 'rotate(180deg)',
                },
                button: {
                  padding: '0 20px',
                  lineHeight: '1.666',
                  a: {
                    fontSize: '18px',
                  },
                  '&:hover, &:focus': {
                    backgroundColor: themeVars.colors.mint,
                  }
                }
              },
              '@media (min-width: 1200px)': {
                'a, button.subMenuParent': {
                  fontSize: remCalc(20),
                },
                '&:not(:last-child)': {
                  marginRight: remCalc(30),
                }
              }
            }
          }
        }
      },
      '@media (min-width: 1024px)': {
        '.topBarMobile': {
          opacity: '0.95',
          display: 'flex',
          transform: 'translateY(-100%)',
        },
        '.topBar': {
          display: 'block',
        }
      }
    },
    '@media only screen and (min-width: 1024px)': {
      marginTop: 0,
    }
  },
  'nav.breadcrumb': {
    'ol.breadcrumb__list': {
      listStyleType: 'none',
      display: 'flex',
      'li.breadcrumb__list__item': {
        marginLeft: '10px',
        marginRight: '10px',
        'a.breadcrumb__link': {
          color: themeVars.colors.blue,
          opacity: 0.8,
          transition: 'opacity 0.2s ease-in-out',
          '&:hover': {
            opacity: 1
          }
        },
        '&:first-of-type': {
          marginLeft: 0,
        }
      }
    }
  },
}

export default headerStyles