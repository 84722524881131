import themeVars from '../theme-vars'
import footerOverlay from '../../assets/svg/footer_overlay_business.svg'

const footerStyles = {
  '* footer.footer': { // Added * to make selector key different from residential, so it adds it instead of replacing it, which loses all our base styles
    background: `linear-gradient(120deg, ${themeVars.colors.darkBlue}, ${themeVars.colors.blue})`,
    '.footerOverlay': {
      backgroundImage: `url(${footerOverlay})`,
    },
  }
}

export default footerStyles